/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Card, Row } from "react-bootstrap";
import Text from "../../../components/Text";
import MapdotPopup from "../MapdotPopup";

class MapVenuList extends Component {
  render() {
    const { mapdots, currentCity } = this.props;

    return (
      <div className="mapdot-container">
        <Card className="w-100 h-100" style={{ backgroundColor: "#f5f5f5" }}>
          <Card.Body className="p-3">
            <Row className="gx-3 gy-3">
              {[...mapdots]
                .sort((a, b) => {
                  if (a.city === currentCity && b.city !== currentCity)
                    return -1;
                  if (a.city !== currentCity && b.city === currentCity)
                    return 1;
                  return a.city.localeCompare(b.city);
                })
                .map((mapdot, index) => (
                  <Col
                    key={index}
                    lg={6} // Para pantallas grandes (2 tarjetas por fila)
                    sm={12} // Para pantallas más pequeñas (1 tarjeta por fila)
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card style={{ width: "100%" }}>
                      <Card.Body>
                        <Row>
                          <Col xs={2}>
                            <i
                              className="fa-solid fa-circle-info"
                              style={{
                                fontSize: "20px",
                                color: "#4e6ac5",
                              }}
                            />
                          </Col>
                          <Col>
                            <h5 style={{ fontFamily: "Montserrat" }}>
                              <MapdotPopup mapdot={mapdot} />
                            </h5>
                          </Col>
                        </Row>

                        <hr
                          style={{ margin: "5px 0", border: "1px solid #ccc" }}
                        />

                        <div
                          style={{
                            fontFamily: "Montserrat",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <span>{mapdot.address}</span>
                        </div>
                        <br />
                        <div
                          style={{
                            fontFamily: "Montserrat",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <span>{mapdot.city}</span>
                        </div>
                        <br />

                        <div>
                          <a
                            href={
                              mapdot.coordinatesLatLong.Latitude
                                ? `https://maps.google.com?q=${mapdot.coordinatesLatLong.Latitude},${mapdot.coordinatesLatLong.Longitude}`
                                : `https://maps.google.com?q=${mapdot.coordinatesLatLong.lat},${mapdot.coordinatesLatLong.lng}`
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Row>
                              <Col xs={2}>
                                <i
                                  className="fa-solid fa-directions"
                                  style={{
                                    fontSize: "20px",
                                    color: "#4e6ac5",
                                  }}
                                />
                              </Col>
                              <Col>
                                <Text tid="goGoogle" />
                              </Col>
                            </Row>
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(MapVenuList);
