/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import iphoneMoc from "../../../styles/images/landing/iPhone11.png";

const DownloadAppBanner = (props) => {
  const { dictionary } = props.lang;

  return (
    <Col>
      {/* Primer Card (Ocultar en vista móvil) */}
      <Card className="d-none d-md-block">
        <div className="msg-publicity">
          <div className="m-3">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="grid">
                <figure
                  className="effect-ming"
                  style={{
                    minHeight: "40vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={iphoneMoc}
                    alt="Download App Store"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <figcaption
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      className="text-center"
                      style={{
                        fontFamily: `Montserrat`,
                        fontSize: "35px",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {dictionary.downloadApp.split(" ")[0]}
                      <br />
                      {dictionary.downloadApp.split(" ")[1]}
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>

      {/* Segundo Card */}
      <Card>
        <div className="msg-publicity">
          <div className="m-3">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  minHeight: "20%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  background:
                    "linear-gradient(153.5deg, #54aa7c 0%, #4e6ac5 44.75%, #c870c8 100%)",
                  color: "white",
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    fontFamily: `Montserrat`,
                  }}
                >
                  {dictionary.whatsappContact}
                </p>

                <a
                  href="https://chat.whatsapp.com/HPoSl8lt4EL2RkCyQ4up00"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1.5em",
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp"
                    style={{ fontSize: "2em", marginRight: "8px" }}
                  ></i>
                  {dictionary.SendMessage}
                </a>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(DownloadAppBanner);
