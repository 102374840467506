/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import MapdotPopup from "../MapdotPopup";
import Text from "../../../components/Text";

class BailoMap extends Component {
  render() {
    const {
      mapdotsTodaySearchBy,
      centerLatitude,
      centerLongitude,
      zoomMap,
      pin,
    } = this.props;
    const { dictionary } = this.props.lang;

    return (
      <div className="mapdot-container">
        <Row className="justify-content-center">
          <Col lg={100}>
            <Card style={{ width: "100%", height: "100vh" }}>
              <Card.Body>
                <Map
                  className="markercluster-map h-100"
                  center={[centerLatitude, centerLongitude]}
                  zoom={zoomMap}
                  scrollWheelZoom={false}
                  tap={false}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <MarkerClusterGroup>
                    {mapdotsTodaySearchBy.map((mapdot) => (
                      <Marker
                        key={mapdot._id}
                        position={
                          mapdot.coordinatesLatLong.Latitude
                            ? [
                                mapdot.coordinatesLatLong.Latitude,
                                mapdot.coordinatesLatLong.Longitude,
                              ]
                            : [
                                mapdot.coordinatesLatLong.lat,
                                mapdot.coordinatesLatLong.lng,
                              ]
                        }
                        icon={pin}
                      >
                        <Popup>
                          <>
                            <Row>
                              <Col md={2}>
                                <i
                                  className="fa-solid fa-circle-info"
                                  style={{ fontSize: "20px", color: "#4e6ac5" }}
                                />
                              </Col>
                              <Col xl={15} md={9}>
                                <MapdotPopup mapdot={mapdot} />
                              </Col>
                            </Row>
                            <hr
                              style={{
                                margin: "0 0 5px 0",
                                border: "1px solid #ccc",
                              }}
                            />
                            <div style={{ fontFamily: "Montserrat" }}>
                              {mapdot.categoryIdName !== "teacher" &&
                              mapdot.categoryIdName !== "radio" &&
                              mapdot.categoryIdName !== "musician"
                                ? mapdot.address
                                : dictionary.addressAprox}
                              <br />
                              {mapdot.city}
                              <br />
                              {mapdot.categoryIdName !== "teacher" &&
                                mapdot.categoryIdName !== "radio" &&
                                mapdot.categoryIdName !== "musician" &&
                                dictionary[mapdot.day]}
                              {mapdot.categoryIdName === "radio" && (
                                <a
                                  href={mapdot.website}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {dictionary.radioWebsite}
                                </a>
                              )}
                            </div>
                            <br />
                            {mapdot.categoryIdName !== "teacher" &&
                              mapdot.categoryIdName !== "radio" &&
                              mapdot.categoryIdName !== "musician" && (
                                <a
                                  href={
                                    mapdot.coordinatesLatLong.Latitude
                                      ? `https://maps.google.com?q=${mapdot.coordinatesLatLong.Latitude},${mapdot.coordinatesLatLong.Longitude}`
                                      : `https://maps.google.com?q=${mapdot.coordinatesLatLong.lat},${mapdot.coordinatesLatLong.lng}`
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Row>
                                    <Col md={2}>
                                      <i
                                        className="fa-solid fa-directions"
                                        style={{
                                          fontSize: "20px",
                                          color: "#4e6ac5",
                                        }}
                                      />
                                    </Col>
                                    <Col xl={15} md={9}>
                                      <Text tid="goGoogle" />
                                    </Col>
                                  </Row>
                                </a>
                              )}
                          </>
                        </Popup>
                      </Marker>
                    ))}
                  </MarkerClusterGroup>
                </Map>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(BailoMap);
