/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// import "../../styles/landing/css/styles.scss";
import ReactGA from "react-ga";

import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../components/Breadcrumb";

import MainMap from "../map/MainMap";

import NavLanding from "../../components/NavLanding";

import Text from "../../components/Text";

import logo from "../../styles/images/logo.png";
import iphoneMoc from "../../styles/images/landing/iPhone11.png";
import playStoreIcon from "../../styles/images/landing/playstore.png";
import appStoreIcon from "../../styles/images/landing/appstore.png";

import { Styles } from "./LandingStyles";

ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKING);

const Landing = () => {
  useEffect(() => {
    ReactGA.pageview("landingPage" + window.location.search);
  });

  return (
    <Aux>
      {/* <Breadcrumb /> */}
      <Styles>
        <div className="wrapper">
          <div>
            {/* -- Navigation Section -- */}

            <NavLanding />

            {/* -- Header Section -- */}

            <div className="main" id="main">
              <div className="main" id="main">
                <div className="hero-section-landing landing-section-custom app-landing">
                  <div></div>
                  <div className="container">
                    <div className="hero-content-custom app-landing-content-custom">
                      <div className="row hero-content-landing">
                        <div className="col-12 col-md-9 header-text-landing">
                          <h1 style={{ fontFamily: `Montserrat` }}>
                            <Text tid="landingTitle" />
                          </h1>
                          <p>
                            <Text tid="subTitleOne" />
                          </p>
                          <p>
                            <Text tid="subTitleTwo" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -- Map Section -- */}

                <div className="counter-section">
                  <div className="container">
                    <MainMap />
                  </div>
                </div>

                {/* -- Download Section -- */}

                <div id="downloadSection" className="download-section-landing">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="download-content-landing">
                          <h2 style={{ fontFamily: `Montserrat` }}>
                            <Text tid="landingTryForFree" />
                          </h2>

                          <div className="hero-content-custom app-landing-content-custom">
                            <div className="row hero-content-landing">
                              <div className="col-md-9 header-text-landing">
                                <p>
                                  <Text tid="subTitleOne" />
                                </p>
                                <p>
                                  <Text tid="subTitleTwo" />
                                </p>
                                <p>
                                  <Text tid="subTitleFour" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="button-group-landing">
                            <div className="moc-download">
                              <img src={iphoneMoc} alt="Download App Store" />
                            </div>
                            <div className="buttons-flex-landing">
                              <div className="text-container">
                                <p>
                                  <Text tid="downloadApp" />
                                </p>
                              </div>
                              <div className="buttons-possition-inline">
                                <div>
                                  <a
                                    href="https://apps.apple.com/es/app/bailo-app/id1582308314?l=en"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={appStoreIcon}
                                      alt="Download App Store"
                                    />
                                  </a>
                                </div>
                                <div>
                                  <a
                                    href="https://play.google.com/store/apps/details?id=com.bailoapp.bailoapp"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={playStoreIcon}
                                      alt="Download Play Store"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="download-mockup wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="800ms"
                      ></div>
                    </div>
                  </div>
                </div>

                {/* -- FAQs Section -- */}

                <div className="client-section no-color">
                  <div className="container text-center no-color">
                    <div className="cta-sub text-center no-color">
                      <h1
                        className="wow fadeInUp"
                        data-wow-delay="0s"
                        style={{ fontFamily: `Montserrat` }}
                      >
                        <Text tid="commonQuestions" />
                      </h1>
                      <p className="wow fadeInUp" data-wow-delay="0.2s">
                        <Text tid="commonQuestionsTextLanding" />
                        <br className="hidden-xs" />
                        <a
                          href="/knowledge-base"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Text tid="commonQuestions" />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                {/* -- brand Section -- */}

                <div className="footer">
                  <div className="container">
                    <div className="col-md-12 text-center">
                      <img src={logo} alt="Bailo.app" />
                      <ul className="footer-menu">
                        <li>
                          <a
                            href="https://www.linkedin.com/company/bailoapp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LinkedIn
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/BailoApp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Twitter
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UC_Ps7_ryCvQ0DVO1NTY9ZIQ/about"
                            target="_blank"
                            rel="noreferrer"
                          >
                            YouTube
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/groups/bailoapp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/bailo.app/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Instagram
                          </a>
                        </li>

                        <li>
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text tid="privacyPolicy" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="/terms-of-use"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text tid="termsOfUse" />
                          </a>
                        </li>
                      </ul>
                      <div className="footer-text">
                        <p>
                          <Text tid="withLove" />
                        </p>
                      </div>
                      <div className="footer-text">
                        <p>2018-2025</p>
                        <p>🇦🇷🇩🇪</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </Aux>
  );
};

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(Landing);
